<template>
  <div>
    <h1 class="auth-wrapper__title">
      <span v-if="errorMessage !== 'expired'">Авторизация</span>
      <span v-else>Сессия истекла</span>
    </h1>
    <div v-if="authError" class="auth-wrapper__bg">
      <div class="flex flex-wrap mt-6">
        <div class="w-full lg:w-12/12">
          <div class="text-white px-6 py-4 border-0 relative mb-4 bg-red-500">
            <span class="inline-block align-middle mr-8">Неправильный email или пароль</span>
          </div>
        </div>
      </div>
    </div>
    <form @submit.prevent="signIn">
      <div class="auth-form" style="padding-top: 24px;">
        <div class="auth-form__col">
          <label class="auth-form__label">Email</label>
          <input v-model="email" type="text" class="auth-form__input" placeholder="Email" />
        </div>
        <div class="auth-form__col">
          <label class="auth-form__label">Пароль</label>
          <input v-model="password" type="password" class="auth-form__input" placeholder="Password" />
        </div>
      </div>
      <div class="auth-bottom">
        <button type="submit" class="mb-btn mb-btn_size_xl mb-btn_active auth-bottom__btn">
          Войти
        </button>
      </div>
    </form>
  </div>
</template>
<script>

import authService from '@/services/auth.service'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'LoginPage',
  setup() {
    const router = useRouter()
    const route = useRoute()
    const email = ref('')
    const password = ref('')
    const authError = ref(false)
    const errorMessage = ref(route.query.error_msg)

    const signIn = () => {

      authError.value = false
      errorMessage.value = ''

      authService.signIn({
        username: email.value,
        password: password.value
      })
        .then((signInResponse) => {
          localStorage.setItem('auth', JSON.stringify({
            token: signInResponse.data
          }))

          authService.status()
            .then((response) => {
              if (response.data.id > 0) {
                localStorage.setItem('account', JSON.stringify(response.data))

                setTimeout(() => {
                  router.push({ path: '/my' })
                }, 10)
              }
              authError.value = true
            })
            .catch(() => {
              authError.value = true
            })

          router.push({ path: '/my' })
        })
        .catch(() => {
          authError.value = true
        })
    }

    authService.status()
      .then((response) => {
        if (response.data.id > 0) {
          localStorage.setItem('account', JSON.stringify(response.data))

          router.push({ path: '/my' })
        }
      })
      .catch((error) => {
        console.error(error)
      })

    return {
      email,
      password,
      authError,
      errorMessage,
      signIn
    }
  }
}
</script>
