<template>
  <div class="member-body">
    <PurpleHead />
    <div class="mb-container">
      <div class="mb-group">
        <MemberNav />
        <router-view />
      </div>
      <MemberCopyright />
    </div>
  </div>
</template>

<script>
import PurpleHead from "@/components/Member/PurpleHead.vue";
import MemberNav from "@/components/Member/MemberNav.vue";
import MemberCopyright from "../components/Member/MemberCopyright.vue";

export default {
  name: "member-layout",
  components: {
    PurpleHead,
    MemberNav,
    MemberCopyright,
  }
};
</script>