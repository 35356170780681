<template>
  <div class="flex flex-col text-center w-full mb-20 m-6 p-6 rounded">
    <div class="mb-task-top">
      <div class="mb-task-top__item">
        <label class="mb-task-top__label">
          {{ issue.name }}
        </label>
        <div class="mb-task-top__desc">
          {{ issue.description }}
        </div>
      </div>
      <solutions :solutions="issue.task.solutions" type="DOOMED_KING" :solution="currentSolution"
                 @setSolution="setSolution" @deleteSolution="(id) => $emit('deleteSolution', id)"/>
    </div>

    <div class="flex mx-auto mt-6">
      <div className="blue merida">
        <div ref="board" 
          className="cg-board-wrap mb-cg-wrap"
          style="width: 408px; height: 408px;"></div>
      </div>
    </div>
    <div class="mb-bottom-buttons">
      <button
        v-if="taskIndex > 0"
        class="mb-btn mb-btn_size_lg mb-btn_plr_30"
        @click.prevent="prevIssue()"
        type="button"
      >
        Предыдущая задача
      </button>
      <button
        class="mb-btn mb-btn_size_lg mb-btn_active mb-btn_plr_30"
        @click.prevent="resetPosition()"
        type="button"
      >
        Начальная позиция
      </button>
      <button
        v-if="!invalidSolution"
        class="mb-btn mb-btn_size_lg mb-btn_active mb-btn_plr_30"
        @click.prevent="addSolution()"
        type="button"
      >
        Добавить решение
      </button>
      <button
        v-if="taskIndex != maxIndex - 1"
        class="mb-btn mb-btn_size_lg mb-btn_plr_30"
        @click.prevent="nextIssue()"
        type="button"
      >
        Следующая задача
      </button>
    </div>

  </div>
</template>

<script>
import formatSAN from '@/formatters/san.js'
import {fullFen} from '@/utils.js'
import Solutions from '@/views/my/trainer/Solutions.vue'
import {Chessground} from 'chessground'
import {computed, onMounted, ref} from 'vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'DoomedKing',
  components: {Solutions},
  props: {
    issue: {
      type: Object,
      required: true
    },
    taskIndex: {
      type: Number,
      required: true
    },
    maxIndex: {
      type: Number,
      required: true
    }
  },
  setup(props, {emit}) {
    const board = ref(null)
    const cg = ref(null)

    const currentSolution = ref('')

    const invalidSolution = computed(() => {
      return !currentSolution.value
    })

    const resetPosition = () => {
      console.log('resetPosition: ', board.value)
      currentSolution.value = ''
      cg.value = Chessground(board.value, editorConfig)
    }

    const verifyIfSolutionIsUnique = () => {
      return !props.issue.task.solutions.includes(currentSolution.value)
    }

    const addSolution = () => {

      if (verifyIfSolutionIsUnique()) {
        emit('addSolution', currentSolution.value)
      }
      resetPosition()
    }

    const setSolution = (id) => {
      resetPosition()
      currentSolution.value = props.issue.task.solutions[id]
      cg.value.newPiece({
        color: 'black',
        role: 'king'
      }, currentSolution.value)
    }

    const updateSolution = (key) => {
      currentSolution.value = (key)
    }

    const editorConfig = {
      coordinates: true,
      fen: fullFen(props.issue.task.fen),
      movable: {
        free: false
      },
      events: {
        select: (key) => {
          if (!cg.value.state.pieces.get(key) && !currentSolution.value.key) {
            resetPosition()
            cg.value.newPiece({
              color: 'black',
              role: 'king'
            }, key)
            updateSolution(key)
          }
        }
      }
    }
    onMounted(() => {
      cg.value = Chessground(board.value, editorConfig)
    })

    const prevIssue = () => {
      emit('selectTask', props.taskIndex - 1)
    }

    const nextIssue = () => {
      emit('selectTask', props.taskIndex + 1)
    }

    return {
      currentSolution,
      board,
      cg,
      editorConfig,
      formatSAN,
      resetPosition,
      addSolution,
      setSolution,
      invalidSolution,
      prevIssue,
      nextIssue
    }
  }
}
</script>

<style>
@import '../../../assets/styles/chess.css';
</style>
