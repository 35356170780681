<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white mb-card mb-dashboard">

    <div class="mb-dashboard__head">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h1 class="font-semibold text-lg text-blueGray-700 mb-dashboard__title">Ваши олимпиады</h1>
        </div>
      </div>
    </div>
    <div class="w-full relative flex justify-start w-auto static block justify-start">
      <div class="w-full">
        <div>
          <div class="block w-full overflow-x-auto">
            <div class="mb-table">
              <div class="mb-table__row mb-table__row_head">
                <div class="mb-table__col mb-table__col_head">Олимпиада</div>
                <div class="mb-table__col mb-table__col_head">Дата проведения</div>
                <div class="mb-table__col mb-table__col_head">Статус</div>
                <div class="mb-table__col mb-table__col_head">Результат</div>
              </div>
              <div class="mb-table__body">
                <div v-if="contestsLoading">
                  <div class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <span class="text-gray-500">Загрузка ...</span>
                  </div>
                </div>
                <div v-if="!contestsLoading && contests.length === 0">
                  <div class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <span class="text-gray-500">Вы не принимали участие в олимпиадах</span>
                  </div>
                </div>
                <div
                  class="mb-table__row"
                  v-for="contest in contests"
                  :key="contest.id"
                >
                  <div class="mb-table__col">
                    <div class="mb-table__mobile-title">Олимпиада</div>
                    <div class="mb-table__value">{{ contest.name }}</div>
                  </div>
                  <div class="mb-table__col">
                    <div class="mb-table__mobile-title">Дата проведения</div>
                    <div class="mb-table__value">{{ contest.startedAt }}</div>
                  </div>
                  <div class="mb-table__col">
                    <div class="mb-table__mobile-title">Статус</div>
                    <div class="mb-table__value">
                      <span v-if="contest.status === 'ACTIVE'">
                        <i class="fas fa-circle text-orange-500 mr-1"></i> Проводится
                      </span>
                      <span v-if="contest.status === 'ARCHIVE'">
                        <i class="fas fa-circle text-emerald-500 mr-1"></i> Завершена
                      </span>
                    </div>
                  </div>
                  <div class="mb-table__col">
                    <div class="mb-table__mobile-title">Результат</div>
                    <div class="mb-table__value">
                      <router-link
                        v-if="contest.status === 'ACTIVE'"
                        :to="`/my/${contest.id}/start`"
                        class="mb-btn mb-btn_size_lg mb-btn_active"
                      >
                        Принять участие
                      </router-link>
                      <router-link
                        v-if="contest.status === 'ARCHIVE'"
                        :to="`/my/${contest.id}/result`"
                        class="mb-btn mb-btn_size_lg mb-btn_active"
                      >
                        Посмотреть результат
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import formatDatetime from '@/formatters/datetime.js'
import contestService from '@/services/contest.service'
import {onMounted, ref} from 'vue'
import {useRouter} from 'vue-router/dist/vue-router'

export default {
  name: 'DashboardPage',
  components: {},
  setup() {
    const router = useRouter()
    onMounted(() => {
      const account = JSON.parse(localStorage.getItem('account'))
      if (!account || account.id === 0) {
        router.push({path: '/login'})
      }
    })

    const contests = ref([])
    const contestsLoading = ref(true)

    contestService.getMy()
        .then((response) => {
          contests.value = response.data
          contestsLoading.value = false
        })

    return {
      contests,
      contestsLoading,
      formatDatetime
    }
  }
}
</script>