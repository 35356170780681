<template>
  <div class="flex flex-wrap items-center">
    <div class="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
      <nav class="block">
        <ul class="flex pl-0 rounded list-none flex-wrap mb-pagination">
          <li class="mb-pagination__li mb-pagination__li_arrow">
            <a
              v-if="current > 1"
              @click="$emit('selectTask', current - 2)"
              class="mb-pagination__item"
            >
              <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 13L1 7L7 1"
                  stroke="#2E3192"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </li>
          <li
            v-for="task of counts"
            :key="task"
            class="mb-pagination__li"
            :class="{
              'mb-pagination__li_current': task === current,
              'mb-pagination__li_angle': task === 1 || task === max,
            }"
          >
            <span
              v-if="task === max && current < max - 1"
              class="mb-pagination__dots"
            >
              ...
            </span>
            <a
              @click="$emit('selectTask', task - 1)"
              class="mb-pagination__item"
              :class="{'mb-pagination__item_current': task === current}"
            >
              {{ task }}
            </a>
            <span
              v-if="task === 1 && current > 2"
              class="mb-pagination__dots"
            >
              ...
            </span>
          </li>
          <li class="mb-pagination__li mb-pagination__li_arrow">
            <a
              v-if="current < max"
              @click="$emit('selectTask', current)"
              class="mb-pagination__item"
            >
              <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 13L7 7L1 1"
                  stroke="#2E3192"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>

import {ref} from 'vue'

export default {
  name: 'TasksSelector',
  props: {
    current: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const counts = ref([])
    for (let i = 1; i <= props.max; i++) {
      counts.value.push(i)
    }

    return {
      counts
    }
  }
}
</script>
