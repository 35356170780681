<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full rounded bg-white mb-dashboard">
    <div class="rounded-t mb-0 border-0 mb-dashboard__head">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h1 class="font-semibold text-lg text-blueGray-700 mb-dashboard__title">
            Шахматная олимпиада <span>{{ contest.name }}</span>
          </h1>
        </div>
      </div>
    </div>
    <div class="relative flex flex-col min-w-0 break-words w-full">
      <div v-if="waiting">
        <p class="text-blueGray-400 p-4">Ожидайте подведения итогов ...</p>
      </div>
      <div v-else>
        <ContestNavigation :contest="contest"/>
      </div>
      <div class="mb-table mb-table_fw">
        <div class="mb-table__row mb-table__row_head">
          <div class="mb-table__col mb-table__col_head">Участник</div>
          <div class="mb-table__col mb-table__col_head">Время решения</div>
          <div class="mb-table__col mb-table__col_head">Кол-во баллов</div>
          <div class="mb-table__col mb-table__col_head">Место</div>
        </div>
        <div class="mb-table__body">
          <div
            v-for="member in sortedMembers()"
            :key="member.member.id"
            class="mb-table__row"
          >
            <div class="mb-table__col">
              <div class="mb-table__mobile-title">Участник</div>
              <div class="mb-table__value">{{ humanizeName(member.member) }}</div>
            </div>
            <div class="mb-table__col">
              <div class="mb-table__mobile-title">Время решения</div>
              <div class="mb-table__value">{{ formatDuration(member.duration) }}</div>
            </div>
            <div class="mb-table__col">
              <div class="mb-table__mobile-title">Кол-во баллов</div>
              <div class="mb-table__value">{{ member.points }}</div>
            </div>
            <div class="mb-table__col">
              <div class="mb-table__mobile-title">Место</div>
              <div class="mb-table__value">{{ member.place }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import formatDuration from '@/formatters/duration'
import contestService from '@/services/contest.service'
import {humanizeName} from '@/utils'
import ContestNavigation from '@/views/my/contests/ContestNavigation'
import {ref} from 'vue'
import {useRoute} from 'vue-router'

export default {
  name: 'ContestResult',
  components: {ContestNavigation},
  setup() {
    const route = useRoute()
    const contest = ref({})
    const personalResults = ref([])
    const waiting = ref(true)

    const sortedMembers = () => {
      return personalResults.value.sort((r1, r2) => {
        return r1.place - r2.place
      })
    }

    contestService.getMemberResults(route.params.id)
        .then(response => {
          contest.value = response.data.contest || {}
          personalResults.value = response.data.personalResults || []
          waiting.value = response.data.waiting
        })

    return {
      contest,
      personalResults,
      waiting,
      sortedMembers,
      humanizeName,
      formatDuration
    }
  }
}
</script>
