<template>
  <div>
    <navbar />
    <main>
      <section class="auth-wrapper">
        <div class="auth-wrapper__inner" style="min-height: calc(100vh - 75px);">
          <div class="auth-wrapper__section">
            <div class="auth-wrapper__body">
              <router-view />
            </div>
          </div>
        </div>
        <MemberCopyright />
      </section>
    </main>
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/AuthNavbar.vue";

import registerBg2 from "@/assets/img/register_bg_2.png";
import MemberCopyright from "@/components/Member/MemberCopyright.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Auth',
  data() {
    return {
      registerBg2,
    };
  },
  components: {
    Navbar,
    MemberCopyright,
  },
};
</script>
