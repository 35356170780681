<template>
  <div class="mb-task-top__item">
    <label class="mb-task-top__label">
      Решения
    </label>
        <div v-if="solution.moves.length === 0 && solutions.length === 0" style="min-height: 27px"
      class="mb-task-top__desc">
      Укажите решение на доске ...
        </div>
        <div style="min-height: 27px; margin-top: 20px;" v-if="solutions.length">
              <span v-for="(solution, id) of solutions" :key="id"
                    :class="{ 'bg-emerald-200 text-emerald-600': solution.successfull,
                            'bg-red-200 text-red-600': solution.failed }"
                    class="mb-3 text-xs font-semibold inline-block py-1 px-2 rounded last:mr-0 mr-1">
                  <span v-for="(moves, index) in solution.moves" :key="index">
                    <a @click="$emit('setMove', id, moves[0])">
                      {{ index + 1 }}. {{ formatSAN(moves[0].san) }} {{ ` ` }}
                    </a>
                    <a v-if="moves[1]" @click="$emit('setMove', id, moves[1])">
                      {{ formatSAN(moves[1].san) }} {{ ` ` }}
                    </a>
                  </span>
            </span>
        </div>
        <div class="pt-2">
             <span v-if="solution.moves.length"
                   :class="{
                      'bg-emerald-500 text-white': solution.successfull,
                      'bg-red-600 text-white': solution.failed,
                      'bg-gray-200 text-gray-600': !solution.successfull && !solution.failed
                   }"
                   class="text-xs font-semibold inline-block py-1 px-2 rounded last:mr-0 mr-1">
               <span v-for="(moves, index) in solution.moves" :key="index"
                     @click="$emit('setMove', false, moves[0])">
                    <a>
                      {{ index + 1 }}. {{ formatSAN(moves[0].san) }} {{ ` ` }}
                    </a>
                    <a v-if="moves.length > 1"
                       @click="$emit('setMove', false, moves[1])">
                      {{ formatSAN(moves[1].san) }} {{ ` ` }}
                    </a>
               </span>
          </span>
        </div>
  </div>
</template>

<script>
import formatSAN from '@/formatters/san.js'

export default {
  name: 'GiveawaySolutionsCard',
  props: {
    solution: {
      type: Object,
      required: true
    },
    solutions: {
      type: Array,
      required: true
    },
  },
  setup() {

    return {
      formatSAN,
    }
  }
}
</script>

<style>
</style>
