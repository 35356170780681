<template>
  <div class="flex flex-col text-center w-full mb-20 m-6 p-6 rounded">
    <div class="mb-task-top">
      <div class="mb-task-top__item">
        <label class="mb-task-top__label">
          {{ issue.name }}
        </label>
        <div class="mb-task-top__desc">
          {{ issue.description }}
        </div>
      </div>
      <labyrinth-solutions :solution="currentSolution" :solutions="solutions"
                           @setMove="setMove"/>
    </div>

    <div class="flex mx-auto mt-6">
      <div className="blue merida">
        <div ref="board" 
          className="cg-board-wrap mb-cg-wrap"
          style="width: 408px; height: 408px;"></div>
      </div>
    </div>

    <div v-if="hasSuccessfullSolution()" class="flex mx-auto overflow-hidden mt-6 mb-6">
      <span class="text-white bg-emerald-500 font-semibold py-3 px-6">Задача решена!</span>
    </div>

    <div v-if="showFailed" class="flex mx-auto overflow-hidden mt-6 mb-6">
      <span class="text-white bg-red-500 font-semibold p-3">Указано неправильное решение, попробуйте еще раз!</span>
    </div>
    <div class="mb-bottom-buttons">
      <button
        v-if="taskIndex > 0"
        class="mb-btn mb-btn_size_lg mb-btn_plr_30"
        @click.prevent="prevIssue()"
        type="button"
      >
        Предыдущая задача
      </button>
      <button
        class="mb-btn mb-btn_size_lg mb-btn_active mb-btn_plr_30"
        @click.prevent="resetPosition()"
        type="button"
      >
        Начальная позиция
      </button>
      <button
        v-if="taskIndex != maxIndex - 1"
        class="mb-btn mb-btn_size_lg mb-btn_plr_30"
        @click.prevent="nextIssue()"
        type="button"
      >
        Следующая задача
      </button>
    </div>

  </div>
</template>

<script>
import {Chess} from '@/chess/chess.js.patched/src/chess.ts'
import {formatMove} from '@/chess/trainer/giveaway.js'
import {fenToChangeTurn, targetCapture, targetCheck, targetPosition} from '@/chess/trainer/labirynth.js'
import {getCaptureMove, getPieceColor, rewriteSolution, toDests} from '@/chess/trainer/utils.js'
import formatFEN from '@/formatters/fen.js'
import formatSAN from '@/formatters/san.js'
import LabyrinthSolutions from '@/views/my/trainer/LabyrinthSolutions.vue'
import {Chessground} from 'chessground'
import {onMounted, ref} from 'vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Labyrinth',
  components: {LabyrinthSolutions},
  props: {
    issue: {
      type: Object,
      required: true
    },
    taskIndex: {
      type: Number,
      required: true
    },
    maxIndex: {
      type: Number,
      required: true
    }
  },
  setup(props, {emit}) {
    const board = ref(null)
    const cg = ref(null)
    const chess = ref(null)
    const showFailed = ref(false)

    const startPosition = {
      fen: formatFEN(props.issue.task.fen),
      dests: {}
    }
    let closed = false

    const solutions = ref(props.issue.task.solutions || [])
    const currentSolution = ref({moves: []})
    let lastMove = {index: 0}

    const resetPosition = () => {

      lastMove = {index: 0}
      currentSolution.value = {moves: []}
      updateFen(startPosition.fen, {dests: startPosition.dests})

    }

    const setMove = (solutionId, move) => {

      if (typeof solutionId === 'number') {
        if (!move.end) {
          currentSolution.value = {moves: solutions.value[solutionId].moves.slice(0, move.index)}
        }
      }
      lastMove = move
      if (lastMove.end) {
        updateFen(lastMove.fen, {dests: {}, end: true})
      } else {
        updateFen(lastMove.fen)
      }
    }

    const updateSolution = (move) => {
      rewriteSolution(currentSolution.value, lastMove)
      currentSolution.value.moves.push(move)
    }

    const updateFen = (fen, optParams) => {

      chess.value.load(fen)
      editorConfig.fen = fen

      if (optParams && optParams.dests) {
        editorConfig.movable.dests = optParams.dests
      } else {
        editorConfig.movable.dests = toDests(chess.value)
      }
      if (optParams && optParams.end) {
        cg.value.set(editorConfig)
        closed = true
        cg.value.stop()
      } else {
        if (closed) {
          cg.value = new Chessground(board.value, editorConfig)
        } else {
          cg.value.set(editorConfig)
        }
      }
    }

    const markSolution = (result) => {
      if (result) {
        currentSolution.value.successfull = true
      } else {
        currentSolution.value.failed = true
        showFailed.value = true

        setTimeout(() => {
          showFailed.value = false
        }, 5000)
      }

      solutions.value.push(currentSolution.value)
      emit('addSolution', solutions.value)
    }

    const verifyTarget = (move) => {
      if (props.issue.task.targetType === 'capture') {
        return targetCapture(move, props.issue.task.target)
      } else if (props.issue.task.targetType === 'check') {
        return targetCheck(chess.value)
      } else {
        return targetPosition(move, props.issue.task.target)
      }
    }

    const updateOnEndingMove = (move) => {
      lastMove = formatMove(move, move.after, lastMove.index + 1)
      lastMove.end = true
      updateSolution(lastMove)
    }

    const processSuccessfull = (move) => {
      updateOnEndingMove(move)
      markSolution(true)
      currentSolution.value = {moves: []}
    }

    const processError = (whiteMove, capture) => {
      updateOnEndingMove(whiteMove)
      cg.value.move(capture.from, capture.to)
    }

    const nextMove = (move) => {
      const fen = fenToChangeTurn(chess.value.fen())
      lastMove = formatMove(move, fen, lastMove.index + 1)
      updateSolution(lastMove)
      updateFen(fen)
    }

    const processUserMove = (from, to) => {

      let _move = {from: from, to: to}
      if (to.split('').includes('8')) {
        const piece = chess.value.get(from)
        if (piece.type === 'p') {
          _move.promotion = 'q'
        }
      }

      const move = chess.value.move(_move)
      const capture = getCaptureMove(chess.value)

      if (capture) {
        processError(move, capture)
      } else {
        if (verifyTarget(move)) {
          processSuccessfull(move)
        } else {
          nextMove(move)
        }
      }
    }

    const processAutoMove = (from, to) => {
      const move = chess.value.move({from, to, promotion: 'Q'})
      updateOnEndingMove(move)

      markSolution(false)
      currentSolution.value = {moves: []}
    }

    const editorConfig = {
      coordinates: true,
      fen: props.issue.task.fen,
      movable: {
        free: false,
        dests: {}
      },
      events: {
        move: (orig, dst, capture) => {

          showFailed.value = false

          let color = getPieceColor(cg.value, dst, capture)
          if (color === 'white') {
            processUserMove(orig, dst)
          } else {
            processAutoMove(orig, dst)
          }
        }
      }
    }

    onMounted(() => {

      chess.value = new Chess(startPosition.fen)
      startPosition.dests = toDests(chess.value)

      editorConfig.movable.dests = startPosition.dests
      cg.value = Chessground(board.value, editorConfig)
    })

    const prevIssue = () => {
      emit('selectTask', props.taskIndex - 1)
    }

    const nextIssue = () => {
      emit('selectTask', props.taskIndex + 1)
    }

    const hasSuccessfullSolution = () => {
      return solutions.value.find(s => s.successfull)
    }

    return {
      currentSolution,
      solutions,
      board,
      cg,
      editorConfig,
      showFailed,
      formatSAN,
      resetPosition,
      setMove,
      prevIssue,
      nextIssue,
      hasSuccessfullSolution
    }
  }
}
</script>

<style>
@import '../../../assets/styles/chess.css';
</style>
