<template>
  <div>
    <div v-if="isMember">
      <member-layout />
    </div>
    <div v-else>
      <sidebar />
      <div class="relative md:ml-64 bg-blueGray-100 h-full">
        <admin-navbar />
        <header-stats />
        <div class="px-4 md:px-10 mx-auto w-full h-screen -m-24">
          <router-view />
          <footer-admin />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import HeaderStats from "@/components/Headers/HeaderStats.vue";
import FooterAdmin from "@/components/Footers/FooterAdmin.vue";
import MemberLayout from "./Member.vue";
export default {
  name: "admin-layout",
  setup() {
    const acc = localStorage.getItem('account');
    let isMember = true;

    if (acc) {
      const account = JSON.parse(acc);

      if (!account.roles.includes('MEMBER')) {
        isMember = false;
      }
    }

    return {
      isMember,
    }
  },
  components: {
    AdminNavbar,
    Sidebar,
    HeaderStats,
    FooterAdmin,
    MemberLayout
  },
};
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');
  @import '../assets/styles/member.css';
</style>