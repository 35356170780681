<template>
  <div class="flex flex-col text-center w-full mb-20 m-6 p-6 rounded">
    <div class="mb-task-top">
      <div class="mb-task-top__item">
        <label class="mb-task-top__label">
          {{ issue.name }}
        </label>
        <div class="mb-task-top__desc">
          {{ issue.description }}
        </div>
      </div>
      <solutions
        :solutions="solutions"
        type="CHECKMATE_IN_1_MOVE"
        :solution="currentSolution"
        @setSolution="setSolution"
        @deleteSolution="deleteSolution"
      />
    </div>
    <div class="flex mx-auto mt-6">
      <div
        className="blue merida"
        style="position: relative;"
      >
        <div
          ref="board"
          className="cg-board-wrap mb-cg-wrap"
          style="width: 408px; height: 408px;"
        />
        <promotion
          ref="promotion"
          @piece-selected="onPromotion"
        />
      </div>
    </div>
    <div class="mb-bottom-buttons">
      <button
        v-if="taskIndex > 0"
        class="mb-btn mb-btn_size_lg mb-btn_plr_30"
        @click.prevent="prevIssue()"
        type="button"
      >
        Предыдущая задача
      </button>
      <button
        class="mb-btn mb-btn_size_lg mb-btn_active mb-btn_plr_30"
        @click.prevent="resetPosition()"
        type="button"
      >
        Начальная позиция
      </button>
      <button
        v-if="!invalidSolution"
        class="mb-btn mb-btn_size_lg mb-btn_active mb-btn_plr_30"
        @click.prevent="addSolution()"
        type="button"
      >
        Добавить решение
      </button>
      <button
        v-if="taskIndex != maxIndex - 1"
        class="mb-btn mb-btn_size_lg mb-btn_plr_30"
        @click.prevent="nextIssue()"
        type="button"
      >
        Следующая задача
      </button>
    </div>

  </div>
</template>

<script>
import formatSAN from '@/formatters/san.js'
import {fullFen} from '@/utils'
import Promotion from '@/views/my/trainer/Promotion'
import Solutions from '@/views/my/trainer/Solutions.vue'
import {Chess} from 'chess.js'
import {Chessground} from 'chessground'
import {computed, onMounted, ref} from 'vue'

export default {
  name: 'CheckmateIn1Move',
  components: {Solutions, Promotion},
  props: {
    issue: {
      type: Object,
      required: true
    },
    taskIndex: {
      type: Number,
      required: true
    },
    maxIndex: {
      type: Number,
      required: true
    }
  },
  setup(props, {emit}) {
    const board = ref(null)
    const promotion = ref(null)
    const cg = ref(null)
    const chess = ref(null)

    const solutions = ref(props.issue.task.solutions || [])
    const currentSolution = ref({})

    const invalidSolution = computed(() => {
      return !currentSolution.value.san
    })

    const resetPosition = () => {
      cg.value = Chessground(board.value, editorConfig)
      chess.value.load(editorConfig.fen)
      currentSolution.value = {}
    }

    const verifySolution = () => {
      const cs = typeof currentSolution.value === 'string' ? currentSolution.value : `${currentSolution.value.from}${currentSolution.value.to}${currentSolution.value.promotion && currentSolution.value.promotion.length ? currentSolution.value.promotion : ''}`
      return !solutions.value
          .map(solution => {
            if (typeof solution === 'string') {
              return solution
            } else {
              return `${solution.from}${solution.to}${solution.promotion && solution.promotion.length ? solution.promotion : ''}`
            }
          })
          .includes(cs)
    }

    const addSolution = () => {
      if (verifySolution()) {
        solutions.value.push(currentSolution.value)
        emit('addSolution', solutions.value)
        console.log('addSolutuin', solutions.value)
      }
      currentSolution.value = {}
      resetPosition()
    }

    const deleteSolution = (index) => {
      emit('deleteSolution', index)
      solutions.value = solutions.value.filter((sol, id) => id !== index)
    }

    const updateSolution = (move) => {
      // move.san = move.san.replace('#', '').replace('+', '')
      // move.promotion = move.promotion ? move.promotion.toUpperCase() : move.promotion
      // const javaSolution = [move.from, move.to, move.promotion].join('')
      currentSolution.value = move
    }

    const setSolution = (id) => {
      resetPosition()
      currentSolution.value = solutions.value[id]
      cg.value.move(currentSolution.value.from, currentSolution.value.to)
    }

    const editorConfig = {
      coordinates: true,
      fen: fullFen(props.issue.task.fen),
      movable: {
        free: true,
        dests: undefined,
        color: 'white'
      },
      events: {
        move: (from, to) => {

          if (cg.value.state.pieces.get(to).role === 'pawn' && (to[1] === '1' || to[1] === '8')) {
            promotion.value.show(from, to)
          } else {
            try {
              const move = chess.value.move({from, to}, {strict: false})
              updateSolution(move)
              cg.value.stop()
            } catch (e) {
              console.error(e)
              // мы должны принимать ошибочные ходы
              updateSolution({from, to, san: from + to})
              cg.value.stop()
            }
          }
        }
      }
    }

    const transformPromotion = (piece) => {
      switch (piece) {
        case 'queen':
          return 'q'
        case 'knight':
          return 'n'
        case 'bishop':
          return 'b'
        case 'rook':
          return 'r'
      }
    }

    const onPromotion = (params) => {
      const {orig, dest} = params

      const move = chess.value.move({
        from: orig,
        to: dest,
        promotion: transformPromotion(params.piece),
        flags: 'p'
      })
      updateSolution(move)
      cg.value.stop()

      const piecesDiff = new Map()
      piecesDiff.set(dest, {
        color: 'white',
        role: params.piece,
        promoted: true
      })
      cg.value.setPieces(piecesDiff)
    }

    onMounted(() => {
      chess.value = new Chess(editorConfig.fen)
      // editorConfig.movable.dests = toDests(chess.value)
      cg.value = Chessground(board.value, editorConfig)
    })

    const prevIssue = () => {
      emit('selectTask', props.taskIndex - 1)
    }

    const nextIssue = () => {
      emit('selectTask', props.taskIndex + 1)
    }

    return {
      currentSolution,
      solutions,
      board,
      cg,
      promotion,
      editorConfig,
      invalidSolution,
      formatSAN,
      resetPosition,
      addSolution,
      deleteSolution,
      setSolution,
      onPromotion,
      prevIssue,
      nextIssue
    }
  }
}
</script>

<style>
@import '../../../assets/styles/chess.css';
</style>
