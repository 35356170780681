<template>
  <div class="flex flex-col text-center w-full mb-20 m-6 p-6 rounded">
    <div class="mb-task-top">
      <div class="mb-task-top__item mb-task-top__item_w_405">
        <label class="mb-task-top__label">
          {{ issue.name }}
        </label>
        <div class="mb-task-top__desc">{{ issue.description }}</div>
      </div>
    </div>


    <div class="mb-question">
        <label class="mb-question__label block">
          {{ issue.task.question }}
        </label>
        <div class="mb-question__items">
          <div :key="id" v-for="(answr, id) in issue.task.answers"
              class="mb-question__item">
            <input :id="`answer-${id}`" name="answer" type="radio" :value="id" v-model="answer" class="cursor-pointer"
                  v-on:change="updateSolution">
            <label :for="`answer-${id}`" class="ml-3 cursor-pointer">{{ answr.text }}</label>
          </div>
        </div>
    </div>
    <div class="mb-bottom-buttons">
      <button
        v-if="taskIndex > 0"
        class="mb-btn mb-btn_size_lg mb-btn_plr_30"
        @click.prevent="prevIssue()"
        type="button"
      >
        Предыдущая задача
      </button>
      <button
        v-if="taskIndex != maxIndex - 1"
        class="mb-btn mb-btn_size_lg mb-btn_plr_30"
        @click.prevent="nextIssue()"
        type="button"
      >
        Следующая задача
      </button>
    </div>

  </div>
</template>

<script>

import {ref, watch} from 'vue'

export default {
  name: 'TestQuestion',
  props: {
    issue: {
      type: Object,
      required: true
    },
    taskIndex: {
      type: Number,
      required: true
    },
    maxIndex: {
      type: Number,
      required: true
    }
  },
  setup(props, {emit}) {

    const answer = ref(null)

    watch(
        () => props.issue,
        () => {
          if (props.issue && props.issue.task && props.issue.task.solutions && props.issue.task.solutions.length) {
            answer.value = props.issue.task.solutions[0]
          } else {
            answer.value = null
          }
        },
        {immediate: true}
    )

    const updateSolution = () => {
      emit('addSolution', answer.value)
    }

    const prevIssue = () => {
      console.log(props.taskIndex - 1)
      emit('selectTask', props.taskIndex - 1)
    }

    const nextIssue = () => {
      console.log(props.taskIndex + 1)
      emit('selectTask', props.taskIndex + 1)
    }

    return {
      answer,
      updateSolution,
      prevIssue,
      nextIssue
    }
  }
}
</script>

<style>
</style>
