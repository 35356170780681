<template>
  <div>
    <navbar/>
    <main>
      <section class="auth-wrapper">
        <div class="auth-wrapper__inner min-h-screen">
          <div class="auth-wrapper__section">
            <div class="auth-wrapper__body">
              <h1 class="auth-wrapper__title">Регистрация участника на олимпиаду {{ contest.name }}</h1>
              <div v-if="mode === 'user' && !success">
                <div
                  v-if="!success"
                  className="auth-user"
                >
                  <p class="auth-user__label">Вы уже авторизованы - хотите участвовать в олимпиаде?</p>
                  <form @submit.prevent="apply">
                    <button
                      type="submit"
                      class="mb-btn mb-btn_size_xl mb-btn_active auth-bottom__btn"
                    >
                      Участвовать в олимпиаде
                    </button>
                  </form>
                </div>
              </div>
              <div v-else>
                <div class="auth-wrapper__bg">
                  <div
                    v-if="errorMessage"
                    class="flex flex-wrap mt-6"
                  >
                    <div class="w-full lg:w-12/12">
                      <div class="text-white px-6 py-4 border-0 relative mb-4 bg-red-500">
                        <span class="inline-block align-middle mr-8">
                          <strong>Ошибка:</strong>
                          {{ errorMessage }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="success"
                    class="flex flex-wrap mt-6 mb-6"
                  >
                    <div class="w-full lg:w-12/12">
                      <div class="text-white px-6 py-4 border-0 relative mb-4 bg-emerald-400">
                        <span class="inline-block align-middle mr-8">
                          Вы успешно зарегистрировались на олимпиаду, Вы можете войти в кабинет
                          <a href="/login">по ссылке</a>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="mode === 'error'"
                    class="flex-auto px-4 lg:px-10 py-10 pt-0"
                  >
                    <p class="mt-10">
                      Возможно олимпиада уже закончилась или Вы ввели неправильный адрес регистрации на олимпиаду.
                    </p>
                  </div>
                  <div
                    v-if="!success && mode !== 'user'"
                    class="auth-buttons"
                  >
                    <a
                      href="#"
                      @click.prevent="onNewAccount()"
                      class="mb-btn mb-btn_size_xl"
                      :class="[mode === 'new' ? 'mb-btn_active' : 'mb-btn_white']"
                    >
                      Новый аккаунт
                    </a>
                    <a
                      href="#"
                      @click.prevent="onExistsAccount()"
                      class="mb-btn mb-btn_size_xl"
                      :class="[mode === 'auth' || mode === 'recover' ? 'mb-btn_active' : 'mb-btn_white']"
                    >
                      У Вас уже есть аккаунт?
                    </a>
                  </div>
                </div>
              </div>
              <div v-if="mode === 'auth' && !success" >
                <form @submit.prevent="signIn">
                  <div class="auth-form">
                    <div class="auth-form__col">
                      <label class="auth-form__label">Email</label>
                      <input
                        required="required"
                        v-model="account.email"
                        class="auth-form__input"
                        name="username"
                        type="text"
                        placeholder="Введите ваш Email"
                        autocomplete="off"
                      />
                    </div>
                    <div class="auth-form__col">
                      <label class="auth-form__label">Пароль</label>
                      <input
                        required="required"
                        v-model="account.password"
                        class="auth-form__input"
                        type="password"
                        name="password"
                        placeholder="Введите ваш пароль"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div class="auth-forgot">
                    <a
                      @click.prevent="mode = 'recover'"
                      href="#"
                      class="auth-forgot__link"
                    >
                      Забыли пароль?
                    </a>
                  </div>
                  <div class="auth-bottom">
                    <button
                      type="submit"
                      class="mb-btn mb-btn_size_xl mb-btn_active auth-bottom__btn"
                    >
                      Участвовать в олимпиаде
                    </button>
                  </div>
                </form>
              </div>
              <div v-if="mode === 'recover' && !success">
                <div v-if="recoveryState === 'sent'">
                  <p>На указанный email отправлен код восстановления, введите его.</p>
                  <form @submit.prevent="recoveryConfirm">
                    <div class="auth-form">
                      <div class="auth-form__col">
                        <label class="auth-form__label">
                          Код
                        </label>
                        <input
                          required="required"
                          v-model="account.recoveryCode"
                          class="auth-form__input"
                          name="recoveryCode"
                          type="text"
                          placeholder="Введите код"
                          autocomplete="off"
                        />
                      </div>
                      <div class="auth-form__col">
                        <label class="auth-form__label">
                          Новый пароль
                        </label>
                        <input
                          required="required"
                          v-model="account.newPassword"
                          class="auth-form__input"
                          name="recoveryCode"
                          type="password"
                          placeholder="Введите новый пароль"
                          autocomplete="off"
                        />
                      </div>
                      <div class="auth-form__col">
                        <label class="auth-form__label">
                          Подтверждение нового пароля
                        </label>
                        <input
                          required="required"
                          v-model="account.newPasswordConfirm"
                          class="auth-form__input"
                          name="recoveryCode"
                          placeholder="Подтвердите пароль"
                          type="password"
                          autocomplete="off"
                        />
                      </div>
                    </div>
                    <div class="auth-bottom">
                      <button
                        type="submit"
                        class="mb-btn mb-btn_size_xl mb-btn_active auth-bottom__btn"
                      >
                        Восстановить пароль
                      </button>
                    </div>
                  </form>
                </div>
                <div v-if="recoveryState === 'wait'">
                  <form @submit.prevent="recovery">
                    <div class="auth-form">
                      <div class="auth-form__col">
                        <label class="auth-form__label">
                          Email
                        </label>
                        <input
                          required="required"
                          v-model="account.email"
                          class="auth-form__input"
                          name="username"
                          type="text"
                          placeholder="Введите ваш Email"
                          autocomplete="off"
                        />
                      </div>
                    </div>
                    <div class="auth-bottom">
                      <button
                        type="submit"
                        class="mb-btn mb-btn_size_xl mb-btn_active auth-bottom__btn"
                      >
                        Восстановить пароль
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div v-if="mode === 'new' && !success">
                <form @submit.prevent="join">
                  <div class="auth-form">
                    <div class="auth-form__col auth-form__col_w_33">
                      <label class="auth-form__label">
                        Фамилия
                      </label>
                      <input
                        required="required"
                        v-model="account.lastName"
                        type="text"
                        name="lastName"
                        placeholder="Введите вашу фамилию"
                        class="auth-form__input"
                      />
                    </div>
                    <div class="auth-form__col auth-form__col_w_33">
                      <label class="auth-form__label">
                        Имя
                      </label>
                      <input
                        required="required"
                        v-model="account.firstName"
                        type="text"
                        name="firstName"
                        placeholder="Введите ваше имя"
                        class="auth-form__input"
                      />
                    </div>
                    <div class="auth-form__col auth-form__col_w_33">
                      <label class="auth-form__label">
                        Отчество
                      </label>
                      <input
                        required="required"
                        v-model="account.patronymicName"
                        type="text"
                        name="patronymicName"
                        placeholder="Введите ваше отчество"
                        class="auth-form__input"
                      />
                    </div>
                    <div class="auth-form__col">
                      <label class="auth-form__label">
                        Электронная почта
                      </label>
                      <input
                        required="required"
                        v-model="account.email"
                        type="email"
                        name="username"
                        placeholder="Введите ваш Email"
                        class="auth-form__input"
                        autocomplete="off"
                      />
                    </div>
                    <div class="auth-form__col">
                      <label class="auth-form__label">
                        Округ
                      </label>
                      <Multiselect
                        v-model="account.parentRegion"
                        placeholder="Выбрать..."
                        :options="parentRegions"
                        class="auth-form__select-dropdown auth-form__select-dropdown_icon"
                        @change="onParentRegion()"
                      />
                    </div>
                    <div class="auth-form__col">
                      <label class="auth-form__label">
                        Регион
                      </label>
                      <Multiselect
                        v-model="account.region"
                        placeholder="Выберите регион"
                        :options="regions"
                        @change="onRegion()"
                        class="auth-form__select-dropdown auth-form__select-dropdown_icon"
                        ref="regionSelect"
                      />
                    </div>
                    <!-- TODO: подключить населенный пункт -->
                    <div class="auth-form__col">
                      <label class="auth-form__label">
                        Населенный пункт
                      </label>
                      <input
                        required="required"
                        v-model="account.city"
                        type="text"
                        name="city"
                        placeholder="Введите ваш населенный пункт"
                        class="auth-form__input"
                        autocomplete="off"
                      />
                    </div>
                    <div class="auth-form__col">
                      <label class="auth-form__label">
                        Школа
                      </label>
                      <Multiselect
                        v-model="account.school"
                        placeholder="Выбрать..."
                        :filter-results="false"
                        :min-chars="1"
                        :resolve-on-load="false"
                        :delay="0"
                        :canClear="false"
                        :searchable="true"
                        :noOptionsText="'Введите несколько символов'"
                        :noResultsText="'Школы не найдены'"
                        ref="schoolSelect"
                        :options="async function(query) {
                          return await fetchSchools(query)
                        }"
                        class="auth-form__select-dropdown"
                      />
                    </div>
                    <!-- <div class="auth-form__col">
                      <label class="auth-form__label">
                        Класс
                      </label>
                      <Multiselect
                        v-model="account.class"
                        placeholder="Выбрать..."
                        :filter-results="false"
                        :min-chars="1"
                        :resolve-on-load="false"
                        :delay="0"
                        :canClear="false"
                        :searchable="true"
                        :noOptionsText="'Введите несколько символов'"
                        :noResultsText="'Классы не найдены'"
                        ref="schoolSelect"
                        :options="async function(query) {
                          return await fetchSchools(query)
                        }"
                        class="auth-form__select-dropdown"
                      />
                    </div> -->
                    <!--                  <div class="flex flex-wrap mt-6 mb-6">-->
                    <!--                    <div class="w-full lg:w-12/12">-->
                    <!--                      <div class="relative w-full mb-3">-->
                    <!--                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">-->
                    <!--                          Населенные пункты-->
                    <!--                        </label>-->
                    <!--                        <input-->
                    <!--                            autocomplete="off"-->
                    <!--                            v-model="account.city"-->
                    <!--                            type="text"-->
                    <!--                            name="city"-->
                    <!--                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"-->
                    <!--                        />-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <!--                  </div>-->
                    <div class="auth-form__col auth-form__col_w_50">
                      <label class="auth-form__label">
                        Пароль
                      </label>
                      <input
                        required="required"
                        v-model="account.password"
                        class="auth-form__input"
                        type="password"
                        name="password"
                        autocomplete="off"
                        placeholder="Введите ваш пароль"
                      />
                    </div>
                    <div class="auth-form__col auth-form__col_w_50">
                      <label class="auth-form__label">
                        Подтверждение пароля
                      </label>
                      <input
                        required="required"
                        v-model="account.confirmPassword"
                        class="auth-form__input"
                        type="password"
                        name="passwordConfirm"
                        autocomplete="off"
                        placeholder="Введите ваш пароль"
                      />
                    </div>
                    <!-- TODO: нет полей в дизайне -->
                    <!-- <div class="flex flex-wrap mt-6">
                      <div class="w-full lg:w-12/12">
                        <div class="relative w-full mb-3">
                          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            Пол
                          </label>
                          <Multiselect
                              v-model="account.gender"
                              placeholder="Выберите пол"
                              :options="genders"/>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-wrap mt-6">
                      <div class="w-full lg:w-12/12">
                        <div class="relative w-full mb-3">
                          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            Дата рождения
                          </label>
                          <VueDatePicker
                              v-model="account.birthdayAt"
                              locale="ru"
                              cancelText="Отмена"
                              selectText="Выбрать"
                              timezone="Europe/Moscow"
                              :enable-time-picker="false"
                              format="dd/MM/yyyy"/>
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <div class="auth-form auth-form_tos">
                    <p class="auth-form__school">Если вы не нашли свою школу, пожалуйста, напишите нам об
                      этом с указанием города и
                      номера школы на
                      электронный адрес <a href="mailto:chesscontest@ya.ru" class="auth-form__school-email">chesscontest@ya.ru</a>
                    </p>
                    <div class="auth-form__tos">
                      <input
                        type="checkbox"
                        v-model="account.verify"
                        @change="onVerify()"
                        class="auth-form__tos-checkbox"
                        id="tos"
                      />
                      <label
                        class="auth-form__tos-label"
                        for="tos"
                      >
                        <span class="auth-form__mobile">Согласие на обработку персональных данных</span>
                        <span class="auth-form__desktop">Даю согласие на обработку персональных данных</span>
                      </label>
                    </div>
                    <!-- <div class="flex flex-wrap mt-6">
                      <div class="w-full lg:w-12/12">
                        <div class="relative w-full mb-3">
                          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            Даю согласие на обработку персональных данных
                          </label>
                          <Toggle v-model="account.verify" @change="onVerify()" :disabled="false"/>
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <div class="auth-bottom">
                    <button
                      type="submit"
                      class="mb-btn mb-btn_size_xl mb-btn_active auth-bottom__btn"
                    >
                      Отправить
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <MemberCopyright />
      </section>
    </main>
  </div>
</template>
<script>

import registerBg2 from '@/assets/img/register_bg_2.png'
import Navbar from '@/components/Navbars/AuthNavbar.vue'
import formatDate from '@/formatters/date'
import accountService from '@/services/account.service'
import schoolService from '@/services/api/school.service'
import authService from '@/services/auth.service'
import contestService from '@/services/contest.service'
import regionService from '@/services/region.service'
import Multiselect from '@vueform/multiselect'
// import Toggle from '@vueform/toggle'
import {nextTick, ref} from 'vue'
import {useRouter} from 'vue-router'
import {useRoute} from 'vue-router/dist/vue-router'
import MemberCopyright from '@/components/Member/MemberCopyright.vue';

export default {
  name: 'JoinPage',
  components: {
    Navbar,
    Multiselect,
    // Toggle,
    MemberCopyright,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const mode = ref('new')
    const contest = ref({})
    const roles = ref([
      {id: 'ADMIN', text: 'Администратор'},
      {id: 'MODERATOR', text: 'Модератор'},
      {id: 'CONTROLLER', text: 'Контроллер'},
      {id: 'MENTOR', text: 'Наставник'},
      {id: 'MEMBER', text: 'Участник'}
    ])
    const genders = ref([
      {
        value: 'MALE',
        label: 'Мужской'
      },
      {
        value: 'FEMALE',
        label: 'Женский'
      }
    ])
    const errorMessage = ref('')
    const success = ref(false)
    const parentRegions = ref([])
    const regions = ref([])
    const regionSelect = ref(null)
    const schoolSelect = ref(null)
    const recoveryState = ref('wait')
    const hasGeo = ref(false)

    contestService.getByHash(route.params.id)
        .then((response) => {
          contest.value = response.data
          if (!contest.value.id) {
            mode.value = 'error'
            errorMessage.value = 'Олимпиада не найдена'
          } else {
            if (contest.value.regions && contest.value.regions.length) {
              hasGeo.value = true
              for (const region of contest.value.regions) {
                regions.value.push({
                  label: region.name,
                  value: region.id
                })

                if (region.parent) {
                  if (!parentRegions.value.find(p => p.value === region.parent.id)) {
                    parentRegions.value.push({
                      label: region.parent.name,
                      value: region.parent.id
                    })
                  }
                }
              }
            } else {
              regionService.search({
                contest: contest.value.id,
                root: true,
                sort: 'name',
                size: 1000
              }).then(response => {
                parentRegions.value = response.data.content.map(r => {
                  return {
                    label: r.name,
                    value: r.id
                  }
                })
              })
            }
          }
        })
        .catch((e) => {
          errorMessage.value = e.message
        })

    const account = ref({
      firstName: '',
      lastName: '',
      patronymicName: '',
      gender: 'MALE',
      birthdayAt: '01/01/2010',
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
      verify: false,
      parentRegion: null,
      region: null
    })

    try {
      const currentUser = JSON.parse(localStorage.getItem('auth'))
      if (currentUser) {
        console.log('currentUser', currentUser)
        mode.value = 'user'
      }
    } catch (e) {
      console.log(e)
    }

    const join = () => {
      if (!account.value.email) {
        errorMessage.value = 'Укажите EMAIL'
        return
      }

      if (!account.value.firstName) {
        errorMessage.value = 'Укажите имя'
        return
      }

      if (!account.value.lastName) {
        errorMessage.value = 'Укажите фамилию'
        return
      }

      if (!account.value.password) {
        errorMessage.value = 'Укажите пароль'
        return
      }

      if (account.value.password !== account.value.confirmPassword) {
        errorMessage.value = 'Пароль и его подтверждение не совпадают'
        return
      }

      // TODO validate regions
      if (!account.value.school) {
        errorMessage.value = 'Укажите школу'
        return
      }

      if (!account.value.verify) {
        errorMessage.value = 'Подтвердите согласие на обработку персональных данных'
        return
      }

      errorMessage.value = null

      contestService.joinMember(route.params.id, {
        email: account.value.email,
        username: account.value.email,
        firstName: account.value.firstName,
        lastName: account.value.lastName,
        patronymicName: account.value.patronymicName,
        gender: account.value.gender,
        birthdayAt: formatDate(account.value.birthdayAt),
        password: account.value.password,
        school: {id: account.value.school}
      })
          .then((response) => {
            console.log(response)
            success.value = true
          })
          .catch((error) => {
            console.error(error)
            errorMessage.value = error.response.data.message
          })
    }

    const onParentRegion = () => {
      regionSelect.value.clear()

      if (!hasGeo.value) {
        nextTick(() => {
          regionService.search({
            parent: account.value.parentRegion,
            size: 1000
          }).then(response => {
            regions.value = response.data.content.map(r => {
              return {
                label: r.name,
                value: r.id
              }
            })
          })
        })
      }
    }

    const onRegion = () => {
      schoolSelect.value.clear()
    }

    const fetchSchools = async (query) => {
      const response = await schoolService.search({
        region: account.value.region,
        filter: query.trim().toLowerCase(),
        size: 1000
      })

      return response.data.content.map((school) => {
        return {
          value: school.id,
          label: school.name
        }
      })
    }

    const onVerify = () => {
      account.value.verify = !!account.value.verify
    }

    const signIn = () => {

      errorMessage.value = ''
      authService.signIn({
        username: account.value.email,
        password: account.value.password
      })
          .then((signInResponse) => {
            console.log('success auth: ', signInResponse)

            localStorage.setItem('auth', JSON.stringify({
              token: signInResponse.data
            }))

            contestService.applyMember(route.params.id)
                .then((response) => {
                  router.push(`/my/${response.data.id}/start`)
                })
                .catch((error) => {
                  console.error(error)
                  errorMessage.value = error.response.data.message
                })
          })
          .catch((error) => {
            console.error('error auth: ', error)

            errorMessage.value = 'Ошибка авторизации'
          })
    }

    const recovery = () => {
      errorMessage.value = ''
      accountService.recovery(account.value.email)
          .then((response) => {
            console.log(response.data)

            if (response.data.ok) {
              recoveryState.value = 'sent'
            } else {
              errorMessage.value = 'Email не найден'
            }
          })
          .catch((error) => {
            console.error(error)

            errorMessage.value = 'Email не найден'
          })
    }

    const recoveryConfirm = () => {
      errorMessage.value = ''
      if (account.value.newPassword !== account.value.newPasswordConfirm) {
        errorMessage.value = 'Пароль и его подтверждение не совпадает'
        return
      }

      accountService.recoveryConfirm(account.value.email, account.value.recoveryCode, account.value.newPassword)
          .then((response) => {
            console.log('success', response)

            mode.value = 'auth'
          })
          .catch((error) => {
            console.error('error', error)

            errorMessage.value = 'Ошибка при восстановлении пароля'
          })
    }

    const onNewAccount = () => {
      mode.value = 'new'
      errorMessage.value = null
    }

    const onExistsAccount = () => {
      mode.value = 'auth'
      errorMessage.value = null
    }

    const apply = () => {
      errorMessage.value = ''
      contestService.applyMember(route.params.id)
          .then((response) => {
            router.push(`/my/${response.data.id}/start`)
          })
          .catch((error) => {
            console.error(error)
            errorMessage.value = error.response.data.message
          })
    }

    return {
      roles,
      genders,
      account,
      mode,
      contest,
      parentRegions,
      regions,
      regionSelect,
      schoolSelect,
      registerBg2,
      errorMessage,
      success,
      join,
      apply,
      fetchSchools,
      onVerify,
      onParentRegion,
      onRegion,
      signIn,
      recovery,
      recoveryConfirm,
      recoveryState,
      onNewAccount,
      onExistsAccount,
    }
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');
  @import '../assets/styles/member.css';
</style>