<template>
  <div class="flex flex-col text-center w-full mb-20 m-6 p-6 rounded">
    <div class="mb-task-top">
      <div class="mb-task-top__item">
        <label class="mb-task-top__label">
          {{ issue.name }}
        </label>
        <div class="mb-task-top__desc">
          {{ issue.description }}
        </div>
      </div>
      <solutions
        :solutions="solutions"
        :solution="currentSolution"
        type="CHECKMATE_NETWORK"
        @setSolution="setSolution"
      />
    </div>

    <div class="flex mx-auto mt-6">
      <div className="blue merida">
        <div ref="board" 
          className="cg-board-wrap mb-cg-wrap"
          style="width: 408px; height: 408px;"></div>
      </div>
    </div>

    <div v-if="!hasSuccessfullSolution()" class="flex mx-auto mt-6">
      <div class="flex chess-editor white border-2 border-blueGray-600 rounded bg-gray-200">
        <div class="flex">
          <div @click="selectPointer('pointer')"
               :class="{'bg-gray-200': currentPointer !== 'pointer', 'bg-emerald-200': currentPointer === 'pointer'}"
               class="w-1/6 h-16 remove hover:bg-red-600">
            <img width="48" height="48" style="padding: 6px; margin: 8px;"
                 :src="images.pointer"/>
          </div>
          <div @click="selectPointer('remove')"
               :class="{'bg-gray-200': currentPointer !== 'remove', 'bg-emerald-200': currentPointer === 'remove'}"
               class="w-1/6 h-16 hand hover:bg-cyan-200">
            <img width="48" height="48" style="padding: 6px; margin: 8px;"
                 :src="images.remove"/>
          </div>
          <div @click="selectPointer('white.king')"
               :class="{'bg-gray-200': currentPointer !== 'white.king', 'bg-emerald-200': currentPointer === 'white.king'}"
               class="w-1/6 h-16 king hover:bg-cyan-200">
            <img width="48" height="48" style="margin: 6px;"
                 :src="images.white.king"/>
          </div>
          <div @click="selectPointer('white.queen')"
               :class="{'bg-gray-200': currentPointer !== 'white.queen', 'bg-emerald-200': currentPointer === 'white.queen'}"
               class="w-1/6 h-16 queen hover:bg-cyan-200">
            <img width="48" height="48" style="margin: 6px;"
                 :src="images.white.queen"/>
          </div>
          <div @click="selectPointer('white.rook')"
               :class="{'bg-gray-200': currentPointer !== 'white.rook', 'bg-emerald-200': currentPointer === 'white.rook'}"
               class="w-1/6 h-16 rook hover:bg-cyan-200">
            <img width="48" height="48" style="margin: 6px;"
                 :src="images.white.rook"/>
          </div>
          <div @click="selectPointer('white.bishop')"
               :class="{'bg-gray-200': currentPointer !== 'white.bishop', 'bg-emerald-200': currentPointer === 'white.bishop'}"
               class="w-1/6 h-16 bishop hover:bg-cyan-200">
            <img width="48" height="48" style="margin: 6px;"
                 :src="images.white.bishop"/>
          </div>
          <div @click="selectPointer('white.knight')"
               :class="{'bg-gray-200': currentPointer !== 'white.knight', 'bg-emerald-200': currentPointer === 'white.knight'}"
               class="w-1/6 h-16 knight hover:bg-cyan-200">
            <img width="48" height="48" style="margin: 6px;"
                 :src="images.white.knight"/>
          </div>
          <div @click="selectPointer('white.pawn')"
               :class="{'bg-gray-200': currentPointer !== 'white.pawn', 'bg-emerald-200': currentPointer === 'white.pawn'}"
               class="w-1/6 h-16 pawn hover:bg-cyan-200">
            <img width="48" height="48" style="margin: 6px;"
                 :src="images.white.pawn"/>
          </div>
        </div>
      </div>
    </div>

    <div v-if="hasSuccessfullSolution()" class="flex mx-auto overflow-hidden mt-6 mb-6">
      <span class="text-white bg-emerald-500 font-semibold py-3 px-6">Задача решена!</span>
    </div>

    <div v-if="showFailed" class="flex mx-auto overflow-hidden mt-6 mb-6">
      <span class="text-white bg-red-500 font-semibold p-3">Указано неправильное решение, попробуйте еще раз!</span>
    </div>
    <div class="mb-bottom-buttons">
      <button
        v-if="taskIndex > 0"
        class="mb-btn mb-btn_size_lg mb-btn_plr_30"
        @click.prevent="prevIssue()"
        type="button"
      >
        Предыдущая задача
      </button>
      <button
        class="mb-btn mb-btn_size_lg mb-btn_active mb-btn_plr_30"
        @click.prevent="resetPosition()"
        type="button"
      >
        Начальная позиция
      </button>
      <button
        v-if="!invalidSolution"
        class="mb-btn mb-btn_size_lg mb-btn_active mb-btn_plr_30"
        @click.prevent="addSolution()"
        type="button"
      >
        Добавить решение
      </button>
      <button
        v-if="taskIndex != maxIndex - 1"
        class="mb-btn mb-btn_size_lg mb-btn_plr_30"
        @click.prevent="nextIssue()"
        type="button"
      >
        Следующая задача
      </button>
    </div>

  </div>
</template>

<script>
import images from '@/chess/images.js'
import {getPieceSquares} from '@/chess/trainer/network.js'
import formatFEN from '@/formatters/fen.js'
import formatSAN from '@/formatters/san.js'
import Solutions from '@/views/my/trainer/Solutions.vue'
import {Chess} from 'chess.js'
import {Chessground} from 'chessground'
import {computed, onMounted, ref} from 'vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'CheckmateNetwork',
  components: {Solutions},
  props: {
    issue: {
      type: Object,
      required: true
    },
    taskIndex: {
      type: Number,
      required: true
    },
    maxIndex: {
      type: Number,
      required: true
    }
  },
  setup(props, {emit}) {
    const board = ref(null)
    const cg = ref(null)
    const chess = ref(null)
    const showFailed = ref(false)

    const startPosition = {
      fen: formatFEN(props.issue.task.fen),
    }

    const solutions = ref(props.issue.task.solutions || [])
    const currentSolution = ref({board: {}, pieces: []})
    const currentPointer = ref('pointer')

    const invalidSolution = computed(() => {
      return currentSolution.value === {} || !currentSolution.value.board.king
          || currentSolution.value.board.king.length === 0
    })

    const selectPointer = (type) => {
      switch (type) {
        case 'pointer':
        case 'remove':
        case 'white.king':
        case 'white.queen':
        case 'white.rook':
        case 'white.bishop':
        case 'white.knight':
        case 'white.pawn':
          currentPointer.value = type
          break
        default:
          currentPointer.value = 'pointer'
          break
      }
    }

    const resetPosition = () => {
      currentSolution.value = {board: {}, pieces: []}
      updateFen(startPosition.fen)

      cg.value = Chessground(board.value, editorConfig)
    }

    const verifyOwnCheck = (fen) => {
      const fenString = `${fen} w - - 0 1`
      const chess = new Chess(fenString)
      return chess.isCheck()
    }

    const verifyIfSolutionIsUnique = () => {
      return !props.issue.task.solutions.map(sol => JSON.stringify(sol))
          .includes(JSON.stringify(currentSolution.value.pieces))
    }

    const addSolution = () => {

      if (invalidSolution.value) {
        return
      }

      if (verifyIfSolutionIsUnique) {

        currentSolution.value.fen = cg.value.getFen()

        if (verifyOwnCheck(cg.value.getFen())) {
          currentSolution.value.failed = true
        } else {

          const fenString = `${cg.value.getFen()} b - - 0 1`
          chess.value = new Chess(fenString)
          const isCheckmate = chess.value.isCheckmate()

          if (isCheckmate) {
            currentSolution.value.successfull = true
            selectPointer('pointer')
            cg.value.stop()
          } else {
            currentSolution.value.failed = true

            showFailed.value = true

            setTimeout(() => {
              showFailed.value = false
            }, 5000)
          }
        }

        solutions.value.push(currentSolution.value)
        emit('addSolution', solutions.value)
      }
      resetPosition()
    }

    const setSolution = (id) => {

      currentSolution.value = {
        board: {...solutions.value[id].board},
        pieces: [...solutions.value[id].pieces],
        fen: solutions.value[id].fen,
      }

      editorConfig.fen = currentSolution.value.fen
      cg.value.set(editorConfig)
    }

    const updateSolution = (piece, key) => {

      if (currentSolution.value.board[piece]) {
        currentSolution.value.board[piece].push(key)
        currentSolution.value.pieces = getPieceSquares(currentSolution.value.board)
      } else {
        currentSolution.value.board[piece] = [key]
        currentSolution.value.pieces = getPieceSquares(currentSolution.value.board)
      }
    }

    const deleteFromSolution = (piece, key) => {

      if (currentSolution.value.board[piece] &&
          currentSolution.value.board[piece].length > 1) {

        currentSolution.value.board[piece] = currentSolution.value.board[piece]
            .filter(k => k !== key)
        currentSolution.value.pieces = getPieceSquares(currentSolution.value.board)

      } else {
        delete currentSolution.value.board[piece]
        currentSolution.value.pieces = getPieceSquares(currentSolution.value.board)
      }
    }

    const updateFen = (fen) => {
      editorConfig.fen = fen
    }

    const addPiece = (piece, key) => {
      cg.value.newPiece({
        color: 'white',
        role: piece
      }, key)
      updateSolution(piece, key)
    }

    const verifySinglePiece = (piece) => {
      return (!currentSolution.value.board[piece])
    }

    const verifyPiece = (piece) => {
      return !currentSolution.value.board[piece] || currentSolution.value.board[piece].length < 2
    }

    const verifyPawn = () => {
      return !currentSolution.value.board['pawn'] || currentSolution.value.board['pawn'].length < 8
    }

    const removePiece = (key) => {

      const piece = cg.value.state.pieces.get(key)
      if (!piece) {
        return
      }
      deleteFromSolution(piece.role, key)
      cg.value.state.pieces.delete(key)
    }

    const editorConfig = {
      coordinates: true,
      fen: startPosition.fen,
      movable: {
        free: false,
      },
      events: {
        select: (key) => {
          switch (currentPointer.value) {
            case 'remove':
              removePiece(key)
              break
            case 'white.king':
              if (verifySinglePiece('king')) {
                removePiece(key)
                addPiece('king', key)
              }
              break
            case 'white.queen':
              if (verifySinglePiece('queen')) {
                removePiece(key)
                addPiece('queen', key)
              }
              break
            case 'white.rook':
              if (verifyPiece('rook')) {
                removePiece(key)
                addPiece('rook', key)
              }
              break
            case 'white.bishop':
              if (verifyPiece('bishop')) {
                removePiece(key)
                addPiece('bishop', key)
              }
              break
            case 'white.knight':
              if (verifyPiece('knight')) {
                removePiece(key)
                addPiece('knight', key)
              }
              break
            case 'white.pawn':
              if (verifyPawn()) {
                removePiece(key)
                addPiece('pawn', key)
              }
              break
            case 'pointer':
            default:
              break
          }
        }
      }
    }

    onMounted(() => {
      cg.value = Chessground(board.value, editorConfig)
    })

    const prevIssue = () => {
      emit('selectTask', props.taskIndex - 1)
    }

    const nextIssue = () => {
      emit('selectTask', props.taskIndex + 1)
    }

    const hasSuccessfullSolution = () => {
      return solutions.value.some(s => s.successfull)
    }

    return {
      images,
      currentSolution,
      solutions,
      board,
      currentPointer,
      invalidSolution,
      showFailed,
      formatSAN,
      resetPosition,
      addSolution,
      setSolution,
      selectPointer,
      prevIssue,
      nextIssue,
      hasSuccessfullSolution
    }
  }
}
</script>

<style>
@import '../../../assets/styles/chess.css';
</style>
