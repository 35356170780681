<template>
  <div>
    <div class="mb-card mb-nav">
      <div class="mb-nav__left">
        <router-link to="/">
          <img
            src="../../assets/img/dark-logo.png"
            alt="Шахматы в школах"
            class="mb-nav__logo"
          />
        </router-link>
        <router-link
          to="/"
          class="mb-btn"
        >
          Главная
        </router-link>
      </div>
      <div class="mb-nav__right">
        <router-link
          to="/my/dashboard"
          class="mb-btn"
        >
          Кабинет
        </router-link>
        <router-link
          to="/my/trainer"
          class="mb-btn"
        >
          Тренажер
        </router-link>
        <router-link
          to="/logout"
          class="mb-btn"
        >
          Выйти
        </router-link>
      </div>
    </div>
    <div class="mb-mobile-nav">
      <router-link to="/">
        <img
          src="../../assets/img/light-logo.png"
          alt="Шахматы в школах"
          class="mb-nav__logo"
        />
      </router-link>
      <button
        class="mb-sandwich"
        @click="toggleMenu"
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="mb-sandwich__icon"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4 9.33333C4 8.97971 4.14048 8.64057 4.39052 8.39052C4.64057 8.14048 4.97971 8 5.33333 8H26.6667C27.0203 8 27.3594 8.14048 27.6095 8.39052C27.8595 8.64057 28 8.97971 28 9.33333C28 9.68696 27.8595 10.0261 27.6095 10.2761C27.3594 10.5262 27.0203 10.6667 26.6667 10.6667H5.33333C4.97971 10.6667 4.64057 10.5262 4.39052 10.2761C4.14048 10.0261 4 9.68696 4 9.33333ZM4 16C4 15.6464 4.14048 15.3072 4.39052 15.0572C4.64057 14.8071 4.97971 14.6667 5.33333 14.6667H26.6667C27.0203 14.6667 27.3594 14.8071 27.6095 15.0572C27.8595 15.3072 28 15.6464 28 16C28 16.3536 27.8595 16.6928 27.6095 16.9428C27.3594 17.1929 27.0203 17.3333 26.6667 17.3333H5.33333C4.97971 17.3333 4.64057 17.1929 4.39052 16.9428C4.14048 16.6928 4 16.3536 4 16ZM4 22.6667C4 22.313 4.14048 21.9739 4.39052 21.7239C4.64057 21.4738 4.97971 21.3333 5.33333 21.3333H26.6667C27.0203 21.3333 27.3594 21.4738 27.6095 21.7239C27.8595 21.9739 28 22.313 28 22.6667C28 23.0203 27.8595 23.3594 27.6095 23.6095C27.3594 23.8595 27.0203 24 26.6667 24H5.33333C4.97971 24 4.64057 23.8595 4.39052 23.6095C4.14048 23.3594 4 23.0203 4 22.6667Z"
            fill="white"
          />
        </svg>
      </button>
    </div>
    <div
      v-if="isMenuOpen"
      class="mb-side-menu"
      @click.self="closeMenu"
    >
      <router-link
        to="/"
        class="mb-side-menu__logo"
        @click="closeMenu"
      >
        <img
          src="../../assets/img/dark-logo.png"
          alt="Шахматы в школах"
          class="mb-nav__logo"
        />
      </router-link>
      <ul class="mb-side-menu__items">
        <li class="mb-side-menu__item">
          <router-link
            to="/"
            class="mb-side-menu__link"
            @click="closeMenu"
          >
            Главная
          </router-link>
        </li>
        <li class="mb-side-menu__item">
          <router-link
            to="/my/dashboard"
            class="mb-side-menu__link"
            @click="closeMenu"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 6L13.6687 9.86694L18 10.2016L14.7 12.9262L15.7082 17L12 14.8169L8.2918 17L9.3 12.9262L6 10.2016L10.3313 9.86694L12 6Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="mb-side-menu__text">Кабинет</span>
          </router-link>
        </li>
        <li class="mb-side-menu__item">
          <router-link
            to="/my/trainer"
            class="mb-side-menu__link"
            @click="closeMenu"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 17.259V6.74104C7 5.96925 7.83721 5.48837 8.50387 5.87726L18.2596 11.5681C18.5904 11.761 18.5904 12.2389 18.2596 12.4319L8.50387 18.1227C7.83721 18.5116 7 18.0308 7 17.259Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="mb-side-menu__text">Тренажер</span>
          </router-link>
        </li>
      </ul>
      <div class="mb-logout">
        <router-link
          to="/logout"
          class="mb-side-menu__link"
          @click="closeMenu"
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.1247 15.0483L21.0995 12.0239M21.0995 12.0239L18.0745 9.04848M21.0995 12.0239L9.09991 12.1217M14.1332 16.0808L14.1415 17.0808C14.1508 18.1853 13.2628 19.088 12.1583 19.097L6.1585 19.1459C5.05397 19.1549 4.15109 18.2668 4.14186 17.1623L4.05829 7.16263C4.04906 6.0581 4.93698 5.1554 6.04151 5.1464L12.0413 5.09751C13.1458 5.08851 14.0487 5.97661 14.058 7.08114L14.0663 8.08111"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="mb-side-menu__text">Выйти</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isMenuOpen: false
      }
    },
    methods: {
      toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
      },
      closeMenu() {
        this.isMenuOpen = false;
      }
    }
  }
</script>