<template>
  <div class="flex flex-col text-center content-center mb-20 m-6 p-6 mb-solution">
    <div class="flex mx-auto mt-6">
      <div className="blue merida">
        <h4 class="font-bold mb-solution__title">Задача №{{ issue.id }} &mdash; {{ formatIssueType() }}</h4>
        <p class="mb-6">{{ issue.description }}</p>
        <div v-if="issue.type !== 'TEST'" ref="board" className="cg-board-wrap"
             style="width: 256px; height: 256px; margin: auto;"></div>
      </div>
    </div>
    <div class="flex mx-auto mt-6 mb-6">
      <span v-if="!showTrace" class="font-bold">Ваши ответы:&nbsp;&nbsp;&nbsp;</span>
      <span v-if="isNoSolutions" class="text-blueGray-600 font-bold">Вы не указали решений</span>
      <template
          v-if="issue.type !== 'GIVEAWAY' && issue.type !== 'LABYRINTH' && issue.type !== 'CHECKMATE_NETWORK'  && issue.type !== 'TEST'">
        <span v-for="success in issue.task.success" :key="success"
              class="text-xs font-semibold inline-block py-1 px-2 rounded-full text-emerald-600 bg-emerald-200 last:mr-0 mr-1">{{
            formatSAN(success)
          }}</span>
        <span v-for="error in issue.task.error" :key="error"
              class="text-xs font-semibold inline-block py-1 px-2 rounded-full text-red-600 bg-red-200 last:mr-0 mr-1">{{
            formatSAN(error)
          }}</span>
      </template>
      <template v-if="issue.type === 'GIVEAWAY'">
        <span v-for="success in issue.task.success" :key="success"
              class="text-xs font-semibold inline-block py-1 px-2 rounded-full text-emerald-600 bg-emerald-200 last:mr-0 mr-1">{{
            success.map((m, i) => `${i + 1}. ${m[0]} ${m.length > 1 ? m[1] : ''}`).join(' ')
          }}</span>
        <span v-for="error in issue.task.error" :key="error"
              class="text-xs font-semibold inline-block py-1 px-2 rounded-full text-red-600 bg-red-200 last:mr-0 mr-1">{{
            error.map((m, i) => `${i + 1}. ${m[0]} ${m.length > 1 ? m[1] : ''}`).join(' ')
          }}</span>
      </template>
      <template v-if="issue.type === 'LABYRINTH'">
        <span v-for="success in issue.task.success" :key="success"
              class="text-xs font-semibold inline-block py-1 px-2 rounded-full text-emerald-600 bg-emerald-200 last:mr-0 mr-1">{{
            success.map((m, i) => `${i + 1}. ${m}`).join(' ')
          }}</span>
        <span v-for="error in issue.task.error" :key="error"
              class="text-xs font-semibold inline-block py-1 px-2 rounded-full text-red-600 bg-red-200 last:mr-0 mr-1">{{
            error.map((m, i) => `${i + 1}. ${m}`).join(' ')
          }}</span>
      </template>
      <template v-if="issue.type === 'CHECKMATE_NETWORK'">
        <span v-for="success in issue.task.success" :key="success"
              class="text-xs font-semibold inline-block py-1 px-2 rounded-full text-emerald-600 bg-emerald-200 last:mr-0 mr-1">{{
            success.join(' ')
          }}</span>
        <span v-for="error in issue.task.error" :key="error"
              class="text-xs font-semibold inline-block py-1 px-2 rounded-full text-red-600 bg-red-200 last:mr-0 mr-1">{{
            error.join(' ')
          }}</span>
      </template>
    </div>
    <template v-if="issue.type === 'TEST'">
      <div class="flex mx-auto mb-5">
        <span>{{ issue.task.question }}</span>
      </div>
    </template>
    <template v-if="issue && issue.task && issue.task.analyze && issue.task.analyze.length > 0">
      <div class="flex mx-auto">
        <h3 class="font-bold">Объяснение:</h3>
      </div>
      <div class="flex mx-auto text-center content-center" v-for="log in issue.task.analyze" :key="log">
        <p>{{ log }}</p>
      </div>
    </template>
  </div>
</template>

<script>
import formatSAN from '@/formatters/san.js'
import {fullFen} from '@/utils'
import {Chessground} from 'chessground'
import {computed, onMounted, ref} from 'vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Solution',
  props: {
    issue: {
      type: Object,
      required: true
    },
    showTrace: {
      type: Boolean,
      required: false,
      defaultValue: false
    }
  },
  setup(props) {
    const board = ref(null)
    const cg = ref(null)
    const isNoSolutions = computed(() => {
      if (props && props.issue && props.issue.task && props.issue.task.success && props.issue.task.error) {
        return (props.issue.task.success.length + props.issue.task.error.length) === 0
      }
      if (props && props.issue && props.issue.task && props.issue.task.solutions) {
        return props.issue.task.solutions.length === 0
      }
      if (props && props.issue && props.issue.type === 'TEST') {
        return props.issue.solutions && props.issue.solutions.length > 0
      }
      return false
    })

    const formatIssueType = () => {
      switch (props.issue.type) {
        case 'CHECKMATE_IN_1_MOVE':
          return 'Мат в 1 ход'
        case 'DOOMED_KING':
          return 'Обреченный король'
        case 'LABYRINTH':
          return 'Лабиринт'
        case 'GIVEAWAY':
          return 'Поддавки'
        case 'CHECKMATE_NETWORK':
          return 'Матовая сеть'
        case 'TEST':
          return 'Тест'
      }
    }

    const editorConfig = {
      coordinates: true,
      fen: props.issue && props.issue.task ? fullFen(props.issue.task.fen) : '',
      movable: {
        free: false,
        dests: undefined,
        color: 'white'
      }
    }

    onMounted(() => {
      if (props.issue.type !== 'TEST') {
        cg.value = Chessground(board.value, editorConfig)
        cg.value.stop()
      }
    })

    return {
      board,
      cg,
      editorConfig,
      formatSAN,
      isNoSolutions,
      formatIssueType
    }
  }
}
</script>

<style>
@import '../../../assets/styles/chess.css';
</style>
