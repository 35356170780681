<template>
  <div class="mb-task-top__item">
    <label class="mb-task-top__label">
      Решения
    </label>
    <div
      v-if="solutions && solutions.length === 0"
      class="mb-task-top__desc"
    >
      Укажите решение на доске ...
    </div>
    <div style="min-height: 27px; margin-top: 20px;" v-if="solutions.length">
      <span
        v-for="(solution, id) of solutions"
        :key="id"
        :class="{
          'bg-gray-200 text-gray-600': !solution.successfull && !solution.failed,
          'bg-emerald-200 text-emerald-600': solution.successfull,
          'bg-red-200 text-red-600': solution.failed
        }"
        class="text-xs font-semibold inline-block py-1 px-2 rounded last:mr-0 mr-1 mb-1"
      >
          <a @click="$emit('setSolution', id)">
            {{ solutionsList[id] }}
          </a>
          <i
            v-if="!solution.successfull && !solution.failed"
            class="pl-3 fa-solid fa-trash"
            @click="$emit('deleteSolution', id)"
          />
      </span>
    </div>
    <div class="pt-2">
      <span>
        {{ currentSolution }}
      </span>
    </div>
  </div>
</template>

<script>
import formatSAN from '@/formatters/san.js'
import {computed} from 'vue'

export default {
  name: 'SolutionsCard',
  props: ['type', 'solutions', 'solution'],
  setup(props) {

    const solutionsList = computed(() => {
      return printSolutions(props.type, props.solutions)
    })

    const currentSolution = computed(() => {
      return printSolution(props.type, props.solution)
    })

    const formatCheckmateIn1MoveSolution = (solution) => {
      if (typeof solution === 'string') {
        return solution
      } else if (solution.lan) {
        return solution.lan
      } else {
        return solution.san
      }
    }

    const printSolution = (type, solution) => {
      const formatters = {
        'CHECKMATE_IN_1_MOVE': () => formatCheckmateIn1MoveSolution(solution),
        'DOOMED_KING': () => solution.toString(),
        'CHECKMATE_NETWORK': () => solution.pieces.length ? solution.pieces.map(sol => formatSAN(sol.note)).join(', ') : ''
      }
      return formatters[type]()
    }

    const printSolutions = (type, solutions) => {
      return solutions.map(sol => printSolution(type, sol))
    }

    return {
      currentSolution,
      solutionsList,
      printSolution,
      formatSAN
    }
  }
}
</script>

<style>
</style>
