<template>
  <div class="mb-task-top__item">
    <label class="mb-task-top__label">
      Решения
    </label>
        <div v-if="solutions.length === 0" style="min-height: 27px"
      class="mb-task-top__desc">
      Укажите решение на доске ...
        </div>
        <div style="min-height: 27px; margin-top: 20px;" v-if="solutions.length">
            <span v-for="(solution, id) of solutions" :key="id"
                  :class="{ 'bg-emerald-200 text-emerald-600': solution.successfull,
                          'bg-red-200 text-red-600': solution.failed }"
                  class="mb-3 text-xs font-semibold inline-block py-1 px-2 rounded last:mr-0 mr-1">
                <span v-for="(move, index) in solution.moves" :key="index">
                  <a @click="$emit('setMove', id, move)">
                    {{ formatSAN(move.note) }}
                  </a>
                  <a v-if="index < solution.moves.length - 1">
                    {{ `, ` }}
                  </a>
                </span>
          </span>
        </div>
        <div class="pt-2">
             <span v-if="solution.moves.length"
                   class="bg-gray-200 text-gray-600 text-xs font-semibold inline-block py-1 px-2 rounded last:mr-0 mr-1">
                <span v-for="(move, index) in solution.moves" :key="index">
                    <a
                        @click="$emit('setMove', false, move)">
                     {{ formatSAN(move.note) }}
                     </a>
                    <a v-if="index < solution.moves.length - 1">
                        {{ `, ` }}
                    </a>
                  </span>
          </span>
        </div>
  </div>
</template>

<script>
import formatSAN from '@/formatters/san.js'

export default {
  name: 'LabyrinthSolutionsCard',
  props: {
    solutions: {
      type: Array,
      required: true
    },
    solution: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      formatSAN
    }
  }
}
</script>

<style>
</style>
